@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap"); */
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600;700&display=swap');
@import 'styles/dialogue.css';

html,
body,
#root,
.app {
  height: 100%;
  width: 100%;
  font-family: 'Rubik', sans-serif;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-track {
  background: #d1d0d0;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #a4a4a4;
  border-radius: 10px;
  border: 2px solid #8d8c8c;
}

::-webkit-scrollbar-track:hover {
  background: #d7d7d7;
}

/* Dark theme */
[data-theme='dark'] ::-webkit-scrollbar-track {
  background: #242427;
  border-radius: 10px;
}

[data-theme='dark'] ::-webkit-scrollbar-thumb {
  background: #48494e;
  border-radius: 10px;
  border: 2px solid #ddd;
}

[data-theme='dark'] ::-webkit-scrollbar-track:hover {
  background: #2a2b2f;
}